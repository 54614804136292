import { getAbsoluteImgPath } from '@components/common';
import SSOAuth from '@components/common/SSOAuth/login';
import LoginForm from './LoginForm';
import {
  HeadingIcon,
  InfoBanner,
  JoinNow,
  LoginWrap,
  OtherOptWrap,
  PageDesc,
  PageHeading,
  PageWrap,
  TopOptions,
} from './style';

type ILoginProps = {
  localization: any;
  config: any;
  fromForgetPassword: boolean;
  deviceType: string;
  isEmployerLogin: boolean;
};

export const ResponseType = {
  USER_SWAP: 'USER_SWAP',
  SUCCESS: 'SUCCESS',
  TECHNICAL_FAULT: 'TECHNICAL_FAULT',
  USER_DEACTIVATED: 'USER_DEACTIVATED',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  INVALID_EMAIL_PASSWORD: 'INVALID_EMAIL_PASSWORD',
  USER_NOT_EXIST: 'USER_NOT_EXIST',
  EXISTING_USER: 'EXISTING_USER',
  INVALID_USER: 'INVALID_USER',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
  NEWLY_CREATED: 'NEWLY_CREATED',
};

export default function LoginUpdated(props: ILoginProps) {
  const { localization, deviceType } = props;
  const isThemeWLB = process.env.NEXT_PUBLIC_THEME === 'wlb';
  return (
    <PageWrap>
      <LoginWrap>
        <PageHeading id="login-heading">
          {' '}
          {props.isEmployerLogin && (
            <HeadingIcon
              src={getAbsoluteImgPath(
                '/images/icons/streamline-thin/icn-login.svg'
              )}
              width={48}
              height={42}
              alt="icon login"
            />
          )}
          {localization.login_heading}
        </PageHeading>
        <PageDesc id="login-description">
          {localization.login_description}
        </PageDesc>
        {props.isEmployerLogin ? (
          <></>
        ) : (
          <TopOptions>
            <SSOAuth
              isLogin
              isGoogle
              config={props.config}
              isLoginPage={true}
              deviceType={deviceType}
            />
            <OtherOptWrap isLogin>OR</OtherOptWrap>
          </TopOptions>
        )}
        <LoginForm
          deviceType={deviceType}
          localization={localization}
          config={props.config}
          isEmployerLogin={props.isEmployerLogin}
        />
        <JoinNow>
          {localization.label_join_now}{' '}
          <a
            id="join-now"
            href={
              props.isEmployerLogin ? '/members/employers/signup' : '/signup'
            }
          >
            {localization.join_now}
          </a>
        </JoinNow>
      </LoginWrap>
      {props.fromForgetPassword && (
        <InfoBanner id="password-mailed-info">
          {localization.thankyou_msg}
        </InfoBanner>
      )}
    </PageWrap>
  );
}
