import { ResponseType } from '@components/loginUpdated';
import { Button } from '@license-admin/boldfjcomponents';
import { width } from '@styles/device.styled';
import useMediaQuery from '@utils/useMediaQuery';
import { updateUserSettings } from 'helper';
import { REGISTRATION_EVENTS } from 'helper/eventTrackingUtils/constant';
import { useRouter } from 'next/router';
import {
  checkIsFJFeatureAllowed,
  getAbsoluteImgPath,
  setClientSettings,
} from '..';
import { _CONSTANTS } from '../constant';
import { genericClickEvent } from '../event-tracker';
import { SocialLoginBtn } from './style';

type Props = {
  config: any;
  isLogin?: boolean;
  isGoogle?: boolean;
  showOnlyGoogle?: boolean;
  showCompTextOnly?: boolean;
  isLoginPage: boolean;
  deviceType?: string;
};

export default function SSOAuth(props: Props) {
  const Router = useRouter();
  const isRMC = !checkIsFJFeatureAllowed();
  const isMobileView =
    useMediaQuery(width.tablet) || props.deviceType === 'mobile';
  const ssoLogin = (
    e: React.SyntheticEvent,
    callback: any,
    signInSource: 'apple' | 'google'
  ) => {
    e.preventDefault();
    if (!props.isLoginPage) {
      genericClickEvent(REGISTRATION_EVENTS.event_page_click, {
        'click location': 'top',
        'click option': signInSource + ' sso',
      });
    }
    callback(
      window.location.href,
      props.config.productCD,
      'Resumes',
      '',
      null,
      undefined,
      1
    ).then((response: any) =>
      handleSSOResponse(response.userid, response, signInSource)
    );
  };

  const handleSSOResponse = async (
    user_uid: any,
    response: any,
    signInSource: 'apple' | 'google'
  ) => {
    // ! signInSource / user_uid will be used for tracking in future.
    let { claims } = await window.LOGIN.Accounts.getClaims();

    var secure = ';secure';

    // window.document.cookie = 'ssologgedIn=true';
    document.cookie = 'ssologgedIn' + '=' + true + secure + '; path=/;';
    document.cookie =
      'signInSource' + '=' + signInSource + secure + '; path=/;';

    if (response?.status) {
      claims.isNewUser = response.status === ResponseType.NEWLY_CREATED;
    }
    setClientSettings({
      key: _CONSTANTS.CLAIMS,
      value: JSON.stringify(claims),
    });
    let userObj = {
      userId: user_uid,
      first_name: claims.firstName.trim(),
      last_name: claims.lastName.trim(),
      email: claims.email.trim(),
    };
    if (user_uid == -1 || response == null) {
      console.log('Invalid email or password - Technical fault');
    } else {
      if (
        response.status == ResponseType.NEWLY_CREATED ||
        response.status == ResponseType.SUCCESS
      ) {
        document.cookie = 'firstTimeUser=true';
        await updateUserSettings(userObj, props.config);
        await Router.push(isRMC ? '/dashboard' : '/members');
      }
      if (
        response.status == ResponseType.USER_SWAP ||
        response.status == ResponseType.EXISTING_USER
      ) {
        await updateUserSettings(userObj, props.config);
        await Router.push(isRMC ? '/dashboard' : '/members');
      }
    }
  };

  return (
    <SocialLoginBtn
      isLogin={props.isLogin}
      isGoogle={props.isGoogle}
      showOnlyGoogle={props.showOnlyGoogle}
    >
      {props.isGoogle && (
        <Button
          id="google-login"
          buttonType="social"
          altText="google icon"
          icon=""
          buttonSize={isMobileView ? 'sm' : 'xl'}
          text={
            props.showCompTextOnly
              ? 'Google'
              : props.isLoginPage
              ? 'Sign In with Google'
              : 'Sign Up with Google'
          }
          fontWeight="light"
          underlineOnHover
          buttonImg={getAbsoluteImgPath('/images/logos/google_logo.svg')}
          ButtonClass="social-btn"
          clickHandler={(e: React.SyntheticEvent) =>
            ssoLogin(e, window.LOGIN.Accounts.loginGoogle, 'google')
          }
        />
      )}

      {!props.showOnlyGoogle && (
        <Button
          id="apple-login"
          buttonSize={isMobileView ? 'sm' : 'xl'}
          buttonType="social"
          altText="apple icon"
          fullLength
          text={
            props.showCompTextOnly
              ? 'Apple'
              : props.isLoginPage
              ? 'Sign In with Apple'
              : 'Sign Up with Apple'
          }
          fontWeight="light"
          underlineOnHover
          ButtonClass="social-btn"
          buttonImg={getAbsoluteImgPath('/images/logos/apple_logo.svg')}
          clickHandler={(e: React.SyntheticEvent) =>
            ssoLogin(e, window.LOGIN.Accounts.loginApple, 'apple')
          }
        />
      )}
    </SocialLoginBtn>
  );
}
