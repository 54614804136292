import { redirectToSource, setClientSettings } from '@components/common';
import { Regex, _CONSTANTS } from '@components/common/constant';
import Toaster from '@components/common/toaster';
import { Button, FormGroup } from '@license-admin/boldfjcomponents';
import { width } from '@styles/device.styled';
import useMediaQuery from '@utils/useMediaQuery';
import { isPortalContent } from 'helper';
import { loginUser } from 'helper/accounts';
import { getUserID } from 'helper/experiments/experimentActions';
import Router, { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { ResponseType } from '.';
import {
  BtnWrap,
  Forgetlink,
  FormBottomContent,
  LegalText,
  LoginFormWrap,
  WelcomeWrap,
} from './style';

type Props = {
  localization: any;
  config: any;
  deviceType?: string;
  isPartnerPage?: boolean;
  isEmployerLogin?: boolean;
};

type IFormData = {
  email: string;
  emailErr: string;
  password: string;
  passwordErr: string;
};

export default function LoginForm(props: Props) {
  const { localization, config, isPartnerPage } = props;
  const loginHeadingRef: any = useRef(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const [errorDescription, setErrorDescription] = useState(
    localization.email_password_err
  );
  const [isValidationError, setIsValidationError] = useState<boolean>(false);
  const [formData, setFormData] = useState<IFormData>({
    email: '',
    emailErr: '',
    password: '',
    passwordErr: '',
  });

  const router = useRouter();
  const isMobileView =
    useMediaQuery(width.tablet) || props.deviceType === 'mobile';

  const handleEmailChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = ev.target;

    const newFormData: IFormData = { ...formData };
    newFormData.email = value;
    newFormData.emailErr = '';

    setFormData(newFormData);
  };

  const handlePasswordChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = ev.target;
    // Disable single space only
    if (value.trim() === '') {
      value = '';
    }

    const newFormData: IFormData = { ...formData };
    newFormData.password = value;
    newFormData.passwordErr = '';

    setFormData(newFormData);
  };

  const handleSubmit = async (ev: React.SyntheticEvent): Promise<void> => {
    ev.preventDefault();
    setBtnLoader(true);
    if (!validateForm()) {
      let isLoggedInUser = await loginUser(formData, config);

      if (isLoggedInUser?.status && isLoggedInUser?.claims) {
        isLoggedInUser.claims.isNewUser =
          isLoggedInUser.status === ResponseType.NEWLY_CREATED;
      }
      setClientSettings({
        key: _CONSTANTS.CLAIMS,
        value: JSON.stringify(isLoggedInUser.claims),
      });

      if (
        isLoggedInUser.claims?.sub_role === 'Employer' &&
        [3, 4, 5, 6, 10, 11, 12].includes(
          Number(isLoggedInUser.additionalMessage)
        )
      ) {
        await window.LOGIN.Accounts.logOutV2(
          true,
          false,
          'Auth_QA,Auth_Reg,Auth,UserStatus'
        );
        setErrorDescription(
          `Thank you for your interest in using ${isPortalContent}. However, the account you are trying to access has not been approved for activation.  If you have received an invitation from us or have previously used ${isPortalContent} full service in your recruiting and believe this is in error, please contact us at employers@remote.co.`
        );
        setIsValidationError(true);
        setBtnLoader(false);
      } else if (isLoggedInUser.status === ResponseType.EXISTING_USER) {
        // const isPremium = await getPremiumStatus(
        //   isLoggedInUser.claims.user_uid
        // );
        const userID = getUserID();
        // if (userID !== null) {
        //   await getUsersAllExperimentAndUpdateCookie(userID, document.cookie);
        // }
        setIsValidationError(false);
        if (router.query && router.query.dest) {
          await Router.push(router.query.dest as string);
        } else if (isLoggedInUser.claims.sub_role === 'Employer') {
          if (Number(isLoggedInUser.additionalMessage) == 1) {
            await Router.push('/members/employers');
          } else {
            // ! If user redirected to survey page after login
            await window.LOGIN.Accounts.logOutV2(
              true,
              false,
              'Auth_QA,Auth_Reg,Auth,UserStatus'
            );
            // await Router.push('/members/employers/survey');
            window.location.href = '/members/employers/survey';
          }
        } else if (isLoggedInUser.claims.sub_role === 'AdminUser') {
          await Router.push('/admin');
        } else if (isLoggedInUser.claims.sub_role === 'EconMember') {
          await Router.push('/members/econ');
        } else {
          await Router.push(redirectToSource(Router.query));
        }
      } else if (
        isLoggedInUser.status === ResponseType.INVALID_USER ||
        isLoggedInUser.status === ResponseType.INVALID_PASSWORD
      ) {
        const newFormData: IFormData = { ...formData };
        newFormData.passwordErr = localization.email_password_err;
        setErrorDescription(localization.email_password_err);
        setFormData(newFormData);
        setIsValidationError(true);
      }
    } else {
      setIsValidationError(true);
      props.isPartnerPage &&
        loginHeadingRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    setBtnLoader(false);
  };
  const validateForm = (): boolean => {
    let isFormDirty: boolean = false;
    let newFormData: IFormData = { ...formData };

    if (!formData.email.trim()) {
      isFormDirty = true;
      newFormData.emailErr = localization.email_blank_err;
    }
    if (formData.email.trim() && !Regex.email.test(formData.email.trim())) {
      isFormDirty = true;
      newFormData.emailErr = localization.email_invalid_err;
    }
    if (!formData.password.trim()) {
      isFormDirty = true;
      newFormData.passwordErr = localization.password_blank_err;
    }

    setFormData(newFormData);
    return isFormDirty;
  };
  return (
    <>
      {isPartnerPage && (
        <WelcomeWrap id="login-welcome" ref={loginHeadingRef}>
          Welcome Back!
        </WelcomeWrap>
      )}
      <LoginFormWrap>
        <form
          onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}
          autoComplete="off"
        >
          <FormGroup
            type="text"
            // value={formData.email}
            id="email"
            label="Email Address"
            autoComplete="off"
            updateFieldData={(ev: React.ChangeEvent<HTMLInputElement>) =>
              handleEmailChange(ev)
            }
            autofocus={true}
            maxLength={50}
          />
          <FormGroup
            type="password"
            id="password"
            label="Password"
            hasPwMask
            updateFieldData={(ev: React.ChangeEvent<HTMLInputElement>) =>
              handlePasswordChange(ev)
            }
          />
          {isValidationError &&
            (isPartnerPage ? (
              <Toaster
                id="invalid-data-toast"
                description="Invalid Email Address/Password"
                status="alert"
              ></Toaster>
            ) : (
              <Toaster
                id="invalid-data-alert"
                description={errorDescription}
                status="alert"
              ></Toaster>
            ))}
          <FormBottomContent>
            <Forgetlink>
              <a
                id="forget-password"
                href={
                  props.isEmployerLogin
                    ? '/reset-password?emp=1'
                    : '/reset-password'
                }
              >
                {localization.label_forget_pass}
              </a>
            </Forgetlink>

            <BtnWrap isPartnerPage={props.isPartnerPage}>
              <Button
                id="login-submit"
                buttonType="tertiary"
                text="Log In"
                btnType="submit"
                fullLength
                buttonSize={isMobileView ? 'md' : 'lg'}
                btnLoader={btnLoader}
                isPointerEvent={btnLoader ? true : false}
              />
            </BtnWrap>
            <LegalText
              dangerouslySetInnerHTML={{
                __html: localization.legal_text,
              }}
            />
          </FormBottomContent>
        </form>
      </LoginFormWrap>
    </>
  );
}
