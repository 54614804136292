import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const SocialLoginBtn = styled.div<{
  isLogin?: boolean;
  isGoogle?: boolean;
  showOnlyGoogle?: boolean;
}>`
  padding: ${({ isLogin }) => (isLogin ? ' 0 0 2.4rem' : ' 0 1.5rem 1.5rem')};
  ${({ isGoogle, showOnlyGoogle }) =>
    isGoogle && !showOnlyGoogle
      ? Mixin.GridLayout({
        columnGap: '4rem',
        mobDimension: '100%',
      })
      : ''};
  ${device.mobileLg}{
    padding:0 0 0.8rem;
    button{
      width: 100%;
      margin-bottom: 1.6rem;
    }
  }
   ${device.mobileTabSm} {
    ${({ isGoogle }) =>
    isGoogle
      ? Mixin.GridLayout({
        rowGap: '2rem',
        gridDimension: ' repeat(auto-fill, minmax(20rem, 1fr))',
      })
      : ''};
  } 
  ${({ theme: { LoginStyle } }) =>
    LoginStyle &&
    css`
      color: ${LoginStyle.Colors.SocialLoginBtn};

      .social-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: ${LoginStyle.BgColors.socialButton};
        color: ${LoginStyle.Colors.socialButton};
        padding: 1.085rem 2.4rem;
        ${device.mobileTab} {
          padding:1.39rem 1.6rem;
        }
      }

      img {
        margin-right: 1.5rem;
        vertical-align: middle;
        ${device.mobileSm} {
          margin-right: 1rem;
          height: 1.9rem;
        }
      }
    `}

`;
