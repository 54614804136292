import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';
export const PageWrap = styled.div`
  padding: 8rem 0;
  ${device.mobileLg} {
    padding: 4rem 0;
  }
`;
export const LoginWrap = styled.div`
  max-width: 63.8rem;
  width: 100%;
  text-align: center;
  padding: 4rem;
  margin: 0 auto;
  border-radius: 0.8rem;
  box-shadow: 0 0 1rem 0 #00000026;

  ${({ theme: { LoginStyle } }) =>
    LoginStyle &&
    css`
      background-color: ${LoginStyle.BgColors.bg};
    `}
  ${device.mobileLg} {
    padding: 4rem 2rem;
  }
`;
export const PageHeading = styled.h1`
  ${Mixin.AllCenter()}
  font-size: 4rem;
  margin-bottom: 0.8rem;
  ${device.mobileLg}{
    font-size: 2.4rem;
  }
`;
export const HeadingIcon = styled.img`
  margin-right: 1.2rem;
`;
export const Forgetlink = styled.div`
  margin-bottom: 2.2rem;
  a{
    font-weight: 600;
  }
`;
export const PageDesc = styled.p`
  margin-bottom: 2.2rem;
  ${device.mobileLg}{
font-size: 1.4rem;
  }
`;

export const BtnWrap = styled.div<{ isPartnerPage?: boolean }>`
  margin: 0 auto 2.2rem;
  text-align: center;
  width: ${({ isPartnerPage }) => (isPartnerPage ? '50%' : '100%')};
  min-width: 16rem;
  ${device.mobileLg} {
    margin: 2.5rem auto 1.6rem;
  }
  button{
    width: auto;
    display: inline-block;
    min-width: 20rem;

  }
`;


export const WelcomeWrap = styled.h2`
  scroll-margin-top: 14rem;
  margin-bottom: 2.4rem;
`;

export const JoinNow = styled.p`
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 700;
`;
export const LoginFormWrap = styled.div`
  margin-top: 2.2rem;

  label {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 1.4rem;
    color: #212529;
  }
`;

export const BreadCrumbWrap = styled.div`
  width: 100%;
`;

export const TopOptions = styled.div`
  padding: 0 2rem;
  ${device.mobileLg}{
    padding: 0 4rem;
  }
`;

export const OtherOptWrap = styled.div<{
  isLogin?: boolean;
  mSignUp?: boolean;
}>`
  text-align: center;
  position: relative;
  font-size: 1.8rem;
  margin: ${({ isLogin }) => (isLogin ? '0' : '0.3rem 0 0 ')};

  ${({ theme: { LoginStyle }, mSignUp }) =>
    LoginStyle &&
    css`

      color: #212529;

      &::before,
      &::after {
        content: '';
        width: ${mSignUp ? '42%' : '45%'};
        ${Mixin.Position({
      value: '50% auto  auto  0',
    })}
        transform:translateY(-50%);
        border-top: 0.1rem solid ${LoginStyle.BdrColors.OtherOptWrap};
      }
      ${device.mobileLg} {
        &::before,
        &::after {
          width: 40%;
        }
      }
    `}
  &::after {
    ${Mixin.Position({
      value: '50% 0  auto  auto',
    })}
  }
`;

export const InfoBanner = styled.div`
  margin: 1.6rem;
  padding: 1.6rem;
  border-radius: 0.4rem;
  text-align: center;
  ${device.mobileLg} {
    margin: 1.6rem 0;
  }
  ${({ theme: { LoginStyle } }) =>
    LoginStyle &&
    css`
      color: ${LoginStyle.Colors.OtherOptWrap};
      background: ${LoginStyle.BgColors.popupbg};
      border: 0.1rem solid ${LoginStyle.BdrColors.popupbdr};
    `}
`;

export const LoginFormGroupWrap = styled.div`
  margin-bottom: 1.6rem;
  text-align: left;

  label {
    color: rgb(33 37 41);
  }
`;
export const FormBottomContent = styled.div`
padding-top: 0.6rem;
`
export const LoginInputField = styled.input`
  border: 0.1rem solid rgb(204 204 204);
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  outline: 0;
`;

export const LegalText = styled.div`
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 400;
  margin: 2.2rem 0;
  text-align: center;

`;
